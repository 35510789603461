import { CatalogKeys, PaintingsStatus } from '~/types/Catalog'

export const getLabel = (key: CatalogKeys, value: number | string, cities: Array<{ title: string, value: string }>) => {
  switch (key) {
    case 'height_from':
      return `Высота от ${value}см`

    case 'height_to':
      return `Высота до ${value}см`

    case 'width_from':
      return `Ширина от ${value}см`

    case 'width_to':
      return `Ширина до ${value}см`

    case 'price_from':
      return `Цена от ${value}руб.`

    case 'price_to':
      return `Цена до ${value}руб.`

    case 'year_from':
      return `Год от ${value}`

    case 'year_to':
      return `Год до ${value}`

    case 'in_sale':
      if (value === '1') {
        return PaintingsStatus['for sale']
      } else {
        return PaintingsStatus['not for sale']
      }

    case 'location':
      return `г. ${cities.find(city => city.value === value)?.title || value}`

    default:
      return value
  }
}

export const getDefaultValue = (key: CatalogKeys, maxPrice: number) => {
  switch (key) {
    case 'height_from':
    case 'price_from':
    case 'width_from':
      return 0

    case 'height_to':
    case 'width_to':
      return 2000

    case 'location':
    case 'in_sale':
      return ''

    case 'price_to':
      return maxPrice

    case 'year_from':
      return 1900

    case 'year_to':
      return new Date().getFullYear()
  }
}

export const isDefaultValue = (key: CatalogKeys, value: number | string, maxPrice: number) => {
  switch (key) {
    case 'height_from':
    case 'price_from':
    case 'width_from':
      return +value === 0

    case 'height_to':
    case 'width_to':
      return +value === 2000

    case 'price_to':
      return +value === maxPrice

    case 'year_from':
      return +value === 1900

    case 'year_to':
      return +value === new Date().getFullYear()

    case 'in_sale':
    case 'location':
      return !value
  }
}

export const groupItemsByColumn = <T>(items: T[] = [], columnsCount: number = 1): Array<T[]> => {
  // console.log('items', items.length)
  const result: Array<T[]> = []

  for (let i = 0; i < columnsCount; i++) {
    result.push([])
  }

  for (let i = 0; i < items.length; i++) {
    result[i % columnsCount].push(items[i])
  }

  return result
}

export const getCurrentPage = (firstPage: number, page: number, lastPage: number) => {
  if (page < firstPage) { return firstPage }
  if (page > lastPage) { return lastPage }

  return page
}


import { defineComponent, nextTick } from 'vue'
import { Swiper as SwiperContainer, SwiperSlide } from 'swiper-vue2'
import 'swiper/swiper-bundle.css'
import { Component } from 'vue/types/umd'
import LinkWithArrow from '../blocks/LinkWithArrow.vue'

export default defineComponent({
  components: {
    SwiperContainer,
    SwiperSlide,
    LinkWithArrow
  },
  props: {
    gap: {
      type: Number,
      default: 30
    },
    linkHref: {
      type: String,
      default: '#'
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    scrollBtns: {
      type: Boolean,
      default: true
    },
    noHeaderWrap: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      required: true
    },
    slidesPerView: {
      type: [Number, String],
      default: 3
    },
    component: {
      type: Object as () => Component
    }
  },
  data () {
    return {
      swiper: null as any
    }
  },
  watch: {
    '$route.query': {
      handler () {
        this.updateSwiper()
      }
    }
  },
  mounted () {
    nextTick().then(() => {
      this.initSwiper()
    })
  },
  methods: {
    initSwiper () {
      // if (!this.swiper) { return }
    },
    updateSwiper () {
      this.swiper.update()
    },
    next () {
      console.log(this.swiper, 'this.swiper')

      console.log(this.swiper.allowSlideNext, 'allowSlideNext')
      this.swiper.slideNext()
    },
    prev () {
      this.swiper.slidePrev()
    },
    handleWheel (ev: WheelEvent) {
      if (ev.deltaY < 0) {
        this.prev()
      } else {
        this.next()
      }
    },
    onSwiper (swiperOn: any) {
      this.swiper = swiperOn
    }
  }
})
